<div class="layout-footer">
    <div class="flex justify-content-between">
        <div class="col-6">
            <h2 class="font-400">{{ constants?.footer?.heading }}</h2>
            <div>
                <button class="p-link layout-topbar-button pr-4">
                    <i class="pi pi-facebook"></i>
                </button>
                <button class="p-link layout-topbar-button pr-4">
                    <i class="pi pi-linkedin"></i>
                </button>
                <button class="p-link layout-topbar-button pr-4">
                    <i class="pi pi-youtube"></i>
                </button>
                <button class="p-link layout-topbar-button pr-4">
                    <i class="pi pi-instagram"></i>
                </button>
            </div>
        </div>
        <div class="col-2">
            <h4 class="font-medium">{{ constants?.footer?.company }}</h4>
            <ul>
                <li>{{ constants?.footer?.about }}</li>
                <li>{{ constants?.footer?.newsLetter }}</li>
            </ul>
        </div>
        <div class="col-2">
            <h4 class="font-medium">{{ constants?.footer?.solutions }}</h4>
            <ul>
                <li>{{ constants?.footer?.contactCenter }}</li>
                <li>{{ constants?.footer?.helpDesk }}</li>
            </ul>
        </div>
        <div class="col-2">
            <h4 class="font-medium">{{ constants?.footer?.resources }}</h4>
            <ul>
                <li>{{ constants?.footer?.contactUs }}</li>
                <li>{{ constants?.footer?.caseStudies }}</li>
            </ul>
        </div>
    </div>
</div>
