import { Component, OnInit } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { DashboardService } from '../demo/service/dashboard.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent implements OnInit {
    constants!:any;
    constructor(public layoutService: LayoutService,
        public dashBoardService: DashboardService) { }
    ngOnInit(): void {
        this.getDynamicData(); 
    }
    getDynamicData() {
        this.dashBoardService
            .getDynamicData()
            .then((data) => {
                this.constants = data;
            })
            .catch((error) => {
                console.error('Error fetching agent dynamic data:', error);
            });
      }

}
