import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { DashboardService } from '../demo/service/dashboard.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    loggedIn: boolean = false;

    constructor(
        public layoutService: LayoutService,
        private dashboardService: DashboardService
    ) {}

    ngOnInit(): void {
            this.items = [
                {
                    items: [
                        {
                            label: 'Profile',
                            icon: 'pi pi-user',
                            routerLink: 'auth/login',
                        },
                        {
                            label: 'Manager Dashboard',
                            icon: 'pi pi-th-large',
                            routerLink: 'manager-dashboard/managerDashboard',
                        },
                        {
                            label: 'Logout',
                            icon: 'pi pi-sign-out',
                            routerLink: 'auth/login',
                        }
                    ]
                }
            ];
        
    
        this.eventSubscribe();

    }
    eventSubscribe() {
        this.dashboardService.getEvent().subscribe((data) => {
            if (data.name === 'loginCheck') {
                this.loggedIn = true;
            }
        });
    }
}
