<!-- <div [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div> -->

<app-topbar></app-topbar>

<div class="routerlet-bg">
    <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
