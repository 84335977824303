<div class="layout-topbar flex align-items-center justify-content-between">
    <a class="layout-topbar-logo" routerLink="">
        <img
            src="assets/layout/images/{{
                layoutService.config().colorScheme === 'light'
                    ? 'logo-dark'
                    : 'logo-white'
            }}.png"
            alt="logo"
            height="600px"
        />
        <span>AI-Gent Plus</span>
    </a>
    <ng-container *ngIf="loggedIn; else notLoggedIn">
        <div
            #topbarmenu
            class="layout-topbar-menu"
            [ngClass]="{
                'layout-topbar-menu-mobile-active':
                    layoutService.state.profileSidebarVisible
            }"
        >
            <ul class="flex align-items-center m-0 p-0">
                <li class="cursor-pointer" routerLink="/home">Home</li>
                <li class="cursor-pointer" routerLink="/agent-dashboard">
                    Agent Dasboard
                </li>
                <li
                    class="cursor-pointer"
                    [routerLink]="['call-dash-board/calldashboard']"
                >
                    Call Dashboard
                </li>
                <li class="cursor-pointer" routerLink="/upload">Upload</li>
            </ul>
            <div class="flex align-items-center">
                <button
                    (click)="menu.toggle($event)"
                    pRipple
                    class="relative cursor-pointer overflow-hidden flex align-items-center p-2 pl-3 text-color profile"
                >
                    <img
                        src="assets/layout/images/{{
                            layoutService.config().colorScheme === 'light'
                                ? 'SGXLogo'
                                : 'SGXLogo'
                        }}.png"
                        alt="logo"
                        width="60px"
                        height="60px"
                    />
                    <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" class="mr-2" shape="circle" /> -->
                    <span class="inline-flex flex-column">
                        <span class="ml-3 font-bold">Sgx Test</span>
                        <span class="text-sm">Admin</span>
                    </span>
                </button>
            </div>
        </div>
    </ng-container>
    <ng-template #notLoggedIn>
        <div #topbarmenu class="layout-topbar-menu">
            <ul class="flex align-items-center m-0 p-0">
                <li class="cursor-pointer">Products</li>
                <li class="cursor-pointer" [routerLink]="['auth/login']">
                    Sign-In
                </li>
            </ul>
            <div class="flex align-items-center">
                <button
                    pButton
                    type="button"
                    class="p-button"
                    severity="contrast"
                >
                    Request Demo
                </button>
            </div>
        </div>
    </ng-template>

    <p-menu
        #menu
        [model]="items"
        appendTo="body"
        [popup]="true"
        styleClass="no-sub-menus"
    ></p-menu>
</div>
